export const EDUCATION = 
[
    {
        id: 0,
        headerTitle: 'Bachelor\'s Degree',
        headerSubTitle: 'University of Alberta',
        headerText: 'From 2015 to 2020, I majored in Electrical Engineering (Cooperative Program) at the University of Alberta. ',
        img: 'uofa.jpeg'
    },
    {
        id: 1,
        headerTitle: 'Master\'s Degree',
        headerSubTitle: 'University of Toronto',
        headerText: 'From 2020 to 2022, I majored in Computer Engineering (M.Eng) at the University of Toronto. ',
        img: 'uoft.jpeg'
    }
];

